const de = {
    headings: {
    // translations related to headings in the document
        // a
        accountPage: 'Account Page',
    },

    flashes: {
    // translations for flash messages (error messages, success messages, toast messages, ...)
        error: {
            // s
            serverInvalidToken: 'Ungültiges Token',
            serverInvalidVerificationCode: 'Der Bestätigungscode ist nicht gültig!',
        },
        validation: {
            // a
            address: 'Bitte eine Adresse eingeben',
            // c
            city: 'Bitte den Wohnort eingeben',
            country: 'Bitte ein Land eingeben',
            // d
            description: 'Bitte eine Beschreibung eingeben',
            // e
            email: 'Bitte eine gültige Email-Adresse eingeben',
            emailTaken: 'Diese Email-Adresse wird schon verwendet',
            // f
            fileMissing: 'Keine Datei ausgewählt',
            firstName: 'Bitte den Vornamen eingeben',
            firstNameShort: 'Der Vorname muss mindestens zwei Zeichen lang sein',
            // l
            lastName: 'Bitte den Nachnamen eingeben',
            lastNameShort: 'Der Nachname muss mindestens zwei Zeichen lang sein',
            // n
            name: 'Bitte einen Namen eingeben',
            // p
            password: 'Bitte ein gültiges Passwort eingeben',
            passwordCurrent: 'Bitte das aktuelle Passwort eingeben',
            passwordIncorrect: 'Das aktuell eingegebene Passwort ist falsch',
            passwordShort: 'Das Passwort muss mindestens 8 Zeichen lang sein',
            postcode: 'Bitte eine PLZ eingeben',
            // t
            title: 'Bitte einen Titel eingeben',
            // v
            value: 'Bitte einen gültigen Wert eingeben.',
            valueNumeric: 'Muss zwingend eine Zahl sein',

        },
        toast: {
            // a
            accountUpdate: 'Das Konto wurde erfolgreich aktualisiert',
            accountUpdateError: 'Das Konto konnte nicht aktualisiert werden',
            // e
            emailSending: 'Das Email wurde erfolgreich versendet',
            emailSendingError: 'Das Email konnte nicht versendet werden ',
            // t
            topicDelete: 'Der Themenbereich wurde erfolgreich gelöscht',
            topicDeleteError: 'Der Themenbereich konnte nicht gelöscht werden',
            topicStoring: 'Der Themenbereich wurde erfolgreich gespeichert',
            topicStoringError: 'Der Themenbereich konnte nicht gespeichert werden',
            topicUpdate: 'Der Themenbereich wurde erfolgreich aktualisiert',
            topicUpdateError: 'Der Themenbereich konnte nicht aktualisiert werden',
        },
    },

    labels: {
    // translations, that are used to identify parts of the document (form labels,table headers, keywords, placeholders,...) [<= 3 words]
        // a
        abbreviation: 'Abkürzung',
        account: 'Benutzerkonto',
        accountDetails: 'Kontodetails',
        accountMy: 'Mein Konto',
        accountUpdate: 'Konto bearbeiten',
        action: 'Aktion | Aktionen',
        active: 'Aktiv',
        addDiamonds: 'Diamonds hinzufügen',
        address: 'Adresse',
        addressAdditional: 'Zusätzliche Adresse',
        affirmation: 'Ja',
        all: 'Alle',
        announcement: 'Ankündigung | Ankündigungen',
        early_tiger: 'Early Tiger | Early Tigers',
        at: 'bei',
        // b
        back: 'Zurück',
        bestseller: 'Bestseller',
        block: 'Block | Blöcke',
        blockAdding: 'Neuer Block',
        blockUpdate: 'Block aktualisieren',
        browse: 'Durchsuchen',
        // c
        cancel: 'Abbrechen',
        change: 'Ändern',
        changed: 'Geändert',
        changeBy: 'Geändert von',
        chargeFree: 'Kostenlos',
        closing: 'Schliessen',
        class: 'Klasse',
        copy: 'Kopie | Kopien',
        codeVerification: 'Bestätigungscode',
        confirmation: 'Bestätigen',
        confirmationAwaiting: 'Ausstehende Bestätigung',
        confirmationRejection: 'Bestätigung abgelehnt',
        contentBlocks: 'Inhalte & Blöcke',
        country: 'Land',
        creator: 'Ersteller',
        created: 'Erstellt',
        createNewAnnouncement: 'Neue Ankündigung erstellen',
        createNewEarlyTiger: 'Neuen Early Tiger erstellen',
        crystalsFurtherPurchaseButton: 'Weitere Diamonds kaufen',
        crystalsManagement: 'Diamonds-Bestellungen verwalten',
        crystalsPurchase: 'Deine gekauften Diamonds',
        crystalsPurchaseButton: 'Diamonds kaufen',
        crystalsPurchaseDone: 'Diamonds gekauft',
        currencySwitzerland: 'CHF ',
        // d
        dateSelection: 'Bitte ein Datum auswählen',
        delete: 'Löschen',
        denial: 'Nein',
        description: 'Beschreibung',
        // e
        earningsMonthly: 'CHF in ',
        ordersMonthly: 'Bestellungen im ',
        editing: 'Bearbeiten',
        email: 'Email',
        emailCurrent: 'Aktuelle Email Adresse',
        emailNew: 'Neue Email Adresse',
        emailVerification: 'Bestätigungs-Email',
        emailVerificationResending: 'Bestätigungs-Email erneut senden',
        emailResending: 'E-Mail nochmals anfordern',
        emptyAnnouncementsTable: 'Im Moment gibt es keine Ankündigungen. Du kannst über den Button oben rechts neue Ankündigungen erstellen.',
        emptyEarlyTigersTable: 'Im Moment gibt es keine Early Tiger. Du kannst über den Button oben rechts neue Early Tiger erstellen.',
        entryMonthly: 'Einträge im ',
        errorCode: 'Fehlercode:',
        evaluationStudents:'Schüler Bewertungen',
        // f
        filterReset: 'Alle Filter zurücksetzen',
        firstname: 'Vorname',
        // i
        id: 'ID',
        income: 'Einkommen',
        // j
        joining: 'Sie treten bei',
        // l
        language: 'Sprache',
        lastname: 'Nachname',
        listModulesPublished: 'Liste der veröffentlichten Module',
        listUserPiQuest: 'Liste aller piQuest Benutzer',
        link: 'Link | Links',
        loadMore: 'Mehr laden',
        login: 'Anmelden',
        logout: 'Abmelden',
        // m
        membershipSince: 'Mitglied seit',
        methodUsed: 'Verwendete Methode',
        module: 'Modul | Module',
        moduleOrder: 'Modulbestellung | Modulbestellungen',
        moduleManagement: 'Modulbestellungen verwalten',
        modulesAddition: 'Weitere Module',
        modulesAuthor: 'Module vom selben Author',
        monthly: 'Monatlich',
        // n
        name: 'Name',
        new: 'Neue',
        // o
        order: 'Sortierung',
        overviewReturn: 'Zurück zur Übersicht',
        // p
        pagination10: '10 pro Seite',
        pagination20: '20 pro Seite',
        pagination50: '50 pro Seite',
        pagination100: '100 pro Seite',
        password: 'Passwort',
        passwordChange: 'Passwort ändern',
        passwordCurrent: 'Aktuelles Passwort',
        passwordForgotten: 'Passwort vergessen?',
        passwordNew: 'Neues Passwort',
        passwordRecovery: 'Passwort Wiederherstellung',
        passwordReset: 'Passwort zurücksetzen',
        passwordVerification: 'Passwort Bestätigung',
        paymentMethod: 'Zahlungsmethode:',
        phone: 'Telefon',
        picture : 'Bild',
        pictureMissing: 'Ohne Bild',
        piquestCurrency: 'Diamonds',
        place: 'Stadt',
        placeholderBrowseAndUpload: 'Durchsuchen und hochladen',
        placeholderFileOrLinkUpload: 'Link oder Datei hochladen',
        placeholderFileSelection: 'Datei auswählen',
        placeholderFileUpload: 'Datei hochladen',
        placeholderFileUploadOriginal: 'Originaldatei hochladen',
        placeholderFileUploadSolution: 'Originaldatei (Lösung) hochladen',
        postcode: 'PLZ',
        preview: 'Vorschau',
        price: 'Preis',
        privacy: 'Privatssphäre',
        private: 'privat',
        publication: 'Veröffentlicht',
        // q
        question: 'Frage | Fragen',
        questionAdding: 'Neue Frage hinzufügen',
        questionDeletion: 'Frage löschen',
        // r
        redemption: 'Einlösen',
        registration: 'Registrieren',
        revealCodes: 'Codes aufdecken',
        roleAdmin: 'Admin',
        roleMaster: 'Master',
        roleStudent: 'Student',
        roleTeacher: 'Teacher',
        // s
        salutation: 'Willkommen zurück, ',
        saving: 'Speichern',
        schoollevel: 'Altersstufe | Altersstufen',
        schoollevelAdaption: 'Altersstufe anpassen',
        schoollevelAddition: 'Neue Altersstufe',
        schooltype: 'Schultyp | Schultypen',
	schooltypeAddition: 'Neuer Schultyp',
        search: 'Suche',
        selection: 'Auswählen',
        selectionAll: 'Alle wählen',
        selectionTags: 'Tags ausgewählt',
        send: 'Senden',
        settings: 'Einstellungen',
        shareAlready: 'Bereits geteilt mit',
        status: 'Status',
        student: 'LernendeR | Lernende',
        solution: 'Lösung | Lösungen',
        subject: 'Schulfach | Schulfächer',
	subjectAddition: 'Neues Schulfach',
        subscription: 'Abo',
        // t
        tag: 'Tag | Tags',
	tagAddition: 'Neues Tag',
        teacher: 'Lehrperson | Lehrpersonen',
        title: 'Titel',
        topic: 'Themenbereich | Themenbereiche',
        topicAdding: 'Neuer Themenbereich',
        topicUpdate: 'Themenbereich aktualisieren',
        total: 'Total',
        transactionCode: 'Transaktions-Code:',
        // u
        update: 'Update',
        updateAnnouncement: 'Ankündigung anpassen',
        updateEarlyTiger: 'Early Tiger anpassen',
        updateAt: 'Aktualisiert am',
	updateSchooltype: 'Schultyp bearbeiten',
	updateSubject: 'Schulfach bearbeiten',
	updateTag: 'Tag bearbeiten',
        upload: 'Hochladen',
        user: 'BenutzerIn | Benutzer',
        uuid: 'uuid',
        // v
        validFrom: 'Gültig ab',
        validTill: 'Gültig bis',
        voucherModalButton: 'Generieren',
        voucherModalTitle: 'Gutschein-Codes generieren',
        voucherRedemption: 'Gutschein einlösen',
        voucherRedemptionDone: 'Gutschein eingelöst',
        voucherTableEmpty: 'Es gibt bisher noch keine generierten Codes.',
        // y
        yearly: 'Jährlich',
    },

    phrases: {
    // translations for small chunks of arbitrary text
        // a
        accountConsultationMissing: 'Du hast noch kein piQuest Konto?',
        accountConsultationExistance: 'Hast du schon ein piQuest Konto?',
        accountConfirmationRequirement: 'HINWEIS: Das Konto muss vom Administrator bestätigt werden, bevor du Module erstellen und verwenden kannst',
        addDiamondsMessage:'Geben Sie die Anzahl der Diamonds ein, die hinzugefügt werden sollen',
        activationSuccess: 'Erfolgreich aktiviert',
        anouncementEvaluations: 'Wir hoffen bald in einer neuen piQuest Version Schüler Bewertungen eines Modules anzubieten.',
        // b
        bestsellerMissing: 'Es wurden keine Bestseller gefunden.',
        // c
        changesDetectionEmpty: 'Keine Änderungen festgestellt!',
        changesSaveSuccess: 'Änderungen erfolgreich gespeichert.',
        confirmationAccountWaiting: 'Bitte warten Sie, bis das Konto bestätigt wurde',
        confirmationData: 'Bitte bestätige deine Angaben',
        confirmationDataConfirmation: 'Vielen Dank für die Bestätigung Ihrer Angaben!',
        confirmationDeletion: 'Wirklich löschen?',
        crystalAccount: 'Du hast noch {{ currentUser.crystals }} Diamonds',
        // e
        emailConfirmation: 'Vielen Dank, die E-Mail-Adresse wurde bestätigt!',
        emailInstructionSending: 'Ein Email mit weiteren Instruktionen wurde an die folgende Email Adresse geschickt: ',
        entryAvailabilityError: 'Es sind noch keine Einträge vorhanden.',
        entryCodeVerification: 'Bitte geben Sie den Bestätigungscode ein',
        entryEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
        entryEmailConfirmation: 'Bitte bestätigen Sie die E-Mail-Adresse',
        entryEmailConfirmationMessage: 'Bestätigungsmail wird versendet...',
	entryEmailError: 'Diese E-Mail-Adresse wird schon verwendet',
        entryFirstname: 'Bitte geben Sie Ihren Vornamen ein',
        entryLastname: 'Bitte geben Sie Ihren Nachnamen ein',
        entryPassword: 'Bitte Passwort eingeben',
        entryPasswordConfirmation: 'Bitte bestätigen Sie das Passwort',
        entryPasswordConfirmationError: 'Die Passwörter stimmen nicht überein',
        entryPasswordRenewal: "Bitte ein neues Passwort eingeben",
        errorOccured: 'Ein Fehler ist aufgetreten, bitte erneut versuchen!',
        // f
        filterModulesEmpty: 'Es wurden keine Module für diese Auswahl gefunden',
        forwarding: 'Sie werden in Kürze weitergeleitet',
        // l
        linkCopyClipboard: 'Der folgende Link wurde ins Clipboard kopiert: ',
        loginSuccess: 'Erfolgreich angemeldet!',
        logoutFirst: 'Du musst dich zuerst abmelden',
        logoutUser: 'Benutzer wird abgemeldet',
        // m
        messageContact: 'Bitte kontaktieren Sie uns, wenn Sie Hilfe benötigen',
        messageUsageStudent: 'Als Lernende/-r kannst du in Klassen teilnehmen und Module verwenden',
        messageUsageTeacher: 'Als Lehrperson kannst du Module erstellen und freigeben',
        // o
        ordersMissing: 'Es wurden keine Bestellungen gefunden.',
        // p
        passwordChangeSuccess: 'Das Passwort wurde erfolgreich geändert',
        passwordEmailRecoverySending: 'Passwort Wiederherstellung E-Mail wurde gesendet',
        passwordEmailRecoveryValidity: 'Passwort Wiederherstellung E-Mail ist nur für 3 Stunden gültig.',
        purchaseCrystals: 'Diamonds aufladen',
        // s
        searchEntryEmpty: 'No entries found',
        selectionAdaption: 'Bitte pass deine Auswahl an',
    },

    links: {
    // translations related to followable links
        footer: {
            // a
            about: 'Über uns',
            // f
            faq: 'FAQ',
            // i
            impressum: 'Impressum',
            // l
            legalNotice: 'Rechtliche Hinweise',
            // s
            service: 'Service',

        },
        navigation: {
            // l
            login: 'Anmelden',
            logout: 'Abmelden',
            // r
            registration: 'Registrieren',
            // s
            system: 'Admin',
        },
    },
};

export default de;