const en = {
    headings: {
    // translations related to headings in the document
        // a
        accountPage: 'Account Page',
    },

    flashes: {
    // translations for flash messages (error messages, success messages, toast messages, ...)
        error: {
            // s
            serverInvalidToken: 'Invalid Token',
            serverInvalidVerificationCode: 'The Verification code is not valid!',
        },
        validation: {
            // a
            address: 'Please enter an address',
            // c
            city: 'Bitte eine Stadt eingeben',
            country: 'Bitte ein Land eingeben',
            // d
            description: 'Please enter a description',
            // e
            email: 'Please enter a valid email',
            emailTaken: 'This e-mail address is already in use',
            // f
            fileMissing: 'Keine Datei ausgewählt',
            firstName: 'Please enter your first name',
            firstNameShort: 'The firstname must be at least two characters long',
            // l
            lastName: 'Please enter your last name',
            lastNameShort: 'The lastname must be at least two characters long',
            // n
            name: 'Please enter a name',
            // p
            password: 'Please enter a valid password',
            passwordCurrent: 'Please enter the current password',
            passwordIncorrect: 'Current typed password is incorrect',
            passwordShort: 'The password must be at least 8 characters long',
            postcode: 'Please enter a ZIP code',
            // t
            title: 'Please enter a title',
            // v
            value: 'Bitte einen gültigen Wert eingeben.',
            valueNumeric: 'Please use a numeric value',

        },
        toast: {
            // a
            accountUpdate: 'The account was successfully updated',
            accountUpdateError: 'Das Konto konnte nicht aktualisiert werden',
            // e
            emailSending: 'The email was successfully sent',
            emailSendingError: 'Das Email konnte nicht versendet werden ',
            // t
            topicDelete: 'The topic was successfully deleted',
            topicDeleteError: 'Der Themenbereich konnte nicht gelöscht werden',
            topicStoring: 'The topic was successfully stored',
            topicStoringError: 'Der Themenbereich konnte nicht gespeichert werden',
            topicUpdate: 'The topic was successfully updated',
            topicUpdateError: 'Der Themenbereich konnte nicht aktualisiert werden',
        },
    },

    labels: {
    // translations, that are used to identify parts of the document (form labels,table headers, keywords, placeholders,...) [<= 3 words]
        // a
        abbreviation: 'Abbreviation',
        account: 'Account',
        accountDetails: 'Account Details',
        accountMy: 'My Account',
        accountUpdate: 'Update Account',
        action: 'Action | Actions',
        active: 'Aktive',
        addDiamonds: 'Add Diamonds',
        address: 'Address',
        addressAdditional: 'Additional Address',
        affirmation: 'Yes',
        all: 'All',
        announcement: 'Announcement | Announcements',
        at: 'at',
        // b
        back: 'Back',
        bestseller: 'Bestseller',
        block: 'Block | Blöcke',
        blockAdding: 'New Block',
        blockUpdate: 'Update Block',
        browse: 'Browse',
        // c
        cancel: 'Cancel',
        change: 'Geändert',
        changed: 'Geändert',
        changeBy: 'Changed by',
        chargeFree: 'kostenlos',
        closing: 'Close',
        copy: 'Copy | Copies',
        class: 'Klasse',
        codeVerification: 'Verification Code',
        confirmation: 'Confirm',
        confirmationAwaiting: 'Ausstehende Bestätigung',
        confirmationRejection: 'Bestätigung abgelehnt',
        contentBlocks: 'Inhalte & Blöcke',
        country: 'Country',
        creator: 'Creator',
        created: 'Created',
        createNewAnnouncement: 'Create new announcement',
        createNewEarlyTiger: 'Create new Early Tiger',
        crystalsFurtherPurchaseButton: 'Weitere Diamonds kaufen',
        crystalsManagement: 'Diamonds-Bestellungen verwalten',
        crystalsPurchase: 'Deine gekauften Diamonds',
        crystalsPurchaseButton: 'Diamonds kaufen',
        crystalsPurchaseDone: 'Diamonds gekauft',
        currencySwitzerland: 'CHF',
        // d
        dateSelection: 'Please select a date',
        delete: 'Delete',
        denial: 'No',
        description: 'Description',
        // e
        earningsMonthly: 'CHF in ',
        ordersMonthly: 'Bestellungen im ',
        editing: 'Edit',
        email: 'Email',
        emailNew: 'Neue Email Adresse',
        emailCurrent: 'Aktuelle Email Adresse',
        emailVerification: 'Verification Email',
        emailVerificationResending: 'Resend verification email',
        emailResending: 'E-Mail nochmals anfordern',
        emptyAnnouncementsTable: 'Currently there are no announcements. You can create a new announcement with the button on the top right.',
        entryMonthly: 'Einträge im ',
        errorCode: 'Error Code:',
        evaluationStudents:'Schüler Bewertungen',
        // f
        filterReset: 'Alle Filter zurücksetzen',
        firstname: 'Firstname',
        // i
        id: 'ID',
        income: 'Income',
        // j
        joining: 'You are joining',
        // l
        language: 'Language',
        lastname: 'Lastname',
        listModulesPublished: 'Liste der veröffentlichten Module',
        listUserPiQuest: 'Liste aller piQuest Benutzer',
        link: 'Link | Links',
        loadMore: 'Mehr laden',
        login: 'Login',
        logout: 'Logout',
        // m
        membershipSince: 'Member since',
        methodUsed: 'Verwendete Methode',
        module: 'Modul | Module',
        moduleOrder: 'Modul order | Module orders',
        moduleManagement: 'Modulbestellungen verwalten',
        modulesAddition: 'Weitere Module',
        modulesAuthor: 'Module vom selben Author',
        monthly: 'Monthly',
        // n
        name: 'Name',
        new: 'New',
        // o
        order: 'Order',
        overviewReturn: 'Zurück zur Übersicht',
        // p
        pagination10: '10 pro Seite',
        pagination20: '20 pro Seite',
        pagination50: '50 pro Seite',
        pagination100: '100 pro Seite',
        password: 'Password',
        passwordChange: 'Change Password',
        passwordCurrent: 'Current Password',
        passwordForgotten: 'Forgot Password?',
        passwordNew: 'New Password',
        passwordRecovery: 'Password Recovery',
        passwordReset: 'Reset Password',
        passwordVerification: 'Passwort Bestätigung',
        paymentMethod: 'Payment Method:',
        phone: 'Phone',
        picture: 'Picture',
        pictureMissing: 'Ohne Bild',
        piquestCurrency: 'Diamonds',
        place: 'City',
        placeholderBrowseAndUpload: 'Browse and upload',
        placeholderFileOrLinkUpload: 'Upload link or file',
        placeholderFileSelection: 'Select file',
        placeholderFileUpload: 'Upload file',
        placeholderFileUploadOriginal: 'Originaldatei hochladen',
        placeholderFileUploadSolution: 'Originaldatei (Lösung) hochladen',
        postcode: 'Postcode',
        preview: 'Preview',
        price: 'Price',
        privacy: 'Privacy',
        private: 'Private',
        publication: 'Veröffentlicht',
        // q
        question: 'Question | Questions',
        questionAdding: 'Add a new question',
        questionDeletion: 'Delete question',
        // r
        redemption: 'Einlösen',
        registration: 'Sign up',
	revealCodes: 'Codes aufdecken',
        roleAdmin: 'Admin',
        roleMaster: 'Master',
        roleStudent: 'Student',
        roleTeacher: 'Teacher',
        // s
        salutation: 'Willkommen zurück, ',
        saving: 'Save',
        schoollevel: 'Altersstufe | Altersstufen',
        schoollevelAdaption: 'Altersstufe anpassen',
	schoollevelAddition: 'Neue Altersstufe',
        schooltype: 'Schultyp | Schultypen',
	schooltypeAddition: 'Neuer Schultyp',
        search: 'Search',
        selection: 'Select',
        selectionAll: 'Select all',
        selectionTags: 'Tags ausgewählt',
        send: 'Send',
        settings: 'Settings',
        shareAlready: 'Bereits geteilt mit',
        status: 'Status',
        student: 'Lernende/-r | Lernende',
        solution: 'Lösung | Lösungen',
        subject: 'Schulfach | Schulfächer',
	    subjectAddition: 'Neues Schulfach',
        subscription: 'Subscription',
        // t
        tag: 'Tag | Tags',
	tagAddition: 'Neues Tag',
        teacher: 'Lehrperson | Lehrpersonen',
        title: 'Title',
        topic: 'Themenbereich | Themenbereiche',
        topicAdding: 'New topic',
        topicUpdate: 'Update topic',
        total: 'Total',
        transactionCode: 'Transaktions-Code:',
        // u
        update: 'Update',
        updateAnnouncement: 'Update announcement',
        updateAt: 'Updated at',
	updateSchooltype: 'Schultyp bearbeiten',
	updateSubject: 'Schulfach bearbeiten',
	updateTag: 'Tag bearbeiten',
        upload: 'Upload',
        user: 'User | User',
        uuid: 'uuid',
        // v
        validFrom: 'Valid from',
        validTill: 'Valid till',
        voucherModalButton: 'Generieren',
        voucherModalTitle: 'Gutschein-Codes generieren',
        voucherRedemption: 'Gutschein eingelöst',
        voucherRedemptionDone: 'Gutschein eingelöst',
        voucherTableEmpty: 'Es gibt bisher noch keine generierten Codes.',
        // y
        yearly: 'yearly',
    },

    phrases: {
    // translations for small chunks of arbitrary text
        // a
        accountConsultationMissing: 'Du hast noch kein piQuest Konto?',
        accountConsultationExistance: 'Hast du schon ein piQuest Konto?',
        accountConfirmationRequirement: 'HINWEIS: Das Konto muss vom Administrator bestätigt werden, bevor du Module erstellen und verwenden kannst',
        addDiamondsMessage:'Enter the number of diamonds to be added',
        activationSuccess: 'Successfully Activated',
        anouncementEvaluations: 'Wir hoffen bald in einer neuen piQuest Version Schüler Bewertungen eines Modules anzubieten.',
        // b
        bestsellerMissing: 'Es wurden keine Bestseller gefunden.',
        // c
        changesDetectionEmpty: 'No changes detected!',
        changesSaveSuccess: 'Changes saved Successfully.',
        confirmationAccountWaiting: 'Please wait for the account to be confirmed',
        confirmationData: 'Please confirm your information',
        confirmationDataConfirmation: 'Thank you for confirming your information!',
        confirmationDeletion: 'Are you sure?',
        crystalAccount: 'Du hast noch {{ currentUser.crystals }} Diamonds',
        // e
        emailConfirmation: 'Thank you, your e-mail address has been confirmed!',
        emailInstructionSending: 'Ein Email mit weiteren Instruktionen wurde an die folgende Email Adresse geschickt: ',
        entryAvailabilityError: 'Es sind noch keine Einträge vorhanden.',
        entryCodeVerification: 'Bitte geben Sie den Bestätigungscode ein',
        entryEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
        entryEmailConfirmation: 'Bitte bestätigen Sie die E-Mail-Adresse',
        entryEmailConfirmationMessage: 'Bestätigungsmail wird versendet...',
	entryEmailError: 'Diese E-Mail-Adresse wird schon verwendet',
        entryFirstname: 'Bitte geben Sie Ihren Vornamen ein',
        entryLastname: 'Bitte geben Sie Ihren Nachnamen ein',
        entryPassword: 'Bitte Passwort eingeben',
        entryPasswordConfirmation: 'Please confirm your password',
        entryPasswordConfirmationError: 'Confirmed password does not match',
        entryPasswordRenewal: "Bitte ein neues Passwort eingeben",
        errorOccured: 'An error has occurred, please try again!',
        // f
        filterModulesEmpty: 'Es wurden keine Module für diese Auswahl gefunden',
        forwarding: 'Sie werden in Kürze weitergeleitet',
        // l
        linkCopyClipboard: 'The following link has been copied to the clipboard: ',
        loginSuccess: 'You have Successfully logged In!',
        logoutFirst: 'You need to logout first',
        logoutUser: 'Benutzer wird abgemeldet',
        // m
        messageContact: 'Please contact us if you need help',
        messageUsageStudent: 'Als Lernende/-r kannst du in Klassen teilnehmen und Module verwenden',
        messageUsageTeacher: 'Als Lehrperson kannst du Module erstellen und freigeben',
        // o
        ordersMissing: 'Es wurden keine Bestellungen gefunden.',
        // p
        passwordChangeSuccess: 'Password changed successfully',
        passwordEmailRecoverySending: 'Password recovery email has been sent',
        passwordEmailRecoveryValidity: 'Password Recovery Email is only valid for 3 hours!',
        purchaseCrystals: 'Diamonds aufladen',
        // s
        searchEntryEmpty: 'Keine Einträge gefunden',
        selectionAdaption: 'Bitte pass deine Auswahl an',
    },

    links: {
    // translations related to followable links
        footer: {
            // a
            about: 'About us',
            // f
            faq: 'FAQ',
            // i
            impressum: 'Impressum',
            // l
            legalNotice: 'Legal Notice',
            // s
            service: 'Service',

        },
        navigation: {
            // l
            login: 'Anmelden',
            logout: 'Abmelden',
            // r
            registration: 'Registrieren',
            // s
            system: 'Admin',
        },
    },
};

export default en;